@import "./../../variable.scss";

.form-pay-main {
  width: 100%;
  background-color: $form-pay-bg;
  overflow: hidden;
  .form-control {
    box-shadow: none;
    border: 1px solid $form-pay-control-border;
    font-size: 12px;
    padding-left: 10px;
  }
  .form-control:focus {
    border: 1px solid $form-pay-control-border;
  }
  .fill-icon {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .form-header {
    background-image: url(./../../public/images/header_line.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    padding-bottom: 10px;
    overflow: hidden;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 10px;
  }
}
.form-logo-left {
  float: left;
}
.form-logo-right {
  float: right;
}
.form-payment {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 5% 2%;
}
.secure-text {
  float: left;
  width: 100%;
}
.secure-text h1 {
  font-size: 22px;
  margin: 0px;
  font-family: $font-700;
  color: $secure-text-color;
}
h1.font-big {
  font-size: 24px;
  margin: 8px;
  font-family: $font-500;
}
h6 {
  font-size: 11px;
  color: $secure-text-color;
}
.iclock {
  padding-right: 20px;
  height: 38px;
}
.icedit {
  padding-right: 20px;
  height: 38px;
}
.form-value {
  font-size: 18px;
  color: #000;
  font-family: $font-500;
}
.form-input-text {
  font-size: 18px !important;
  color: $form-pay-ip-color;
  font-family: $font-500;
}
.form-input-ic {
  background-color: transparent;
}

/* radio button */

input[type="radio"].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type="radio"].css-checkbox + label.css-label,
input[type="radio"].css-checkbox + label.css-label.clr {
  padding-left: 23px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
}

input[type="radio"].css-checkbox:checked + label.css-label,
input[type="radio"].css-checkbox + label.css-label.chk {
  background-position: 0 -18px;
}
label.css-label {
  background-image: url(../../public/images/radio_ic.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 20px;
}
.payment-cards {
  width: 100%;
  float: left;
}
label {
  color: $pay-form-lbl-color;
  font-size: 12px;
  margin-bottom: 0px;
  width: 100%;
}

.formdd_m {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(8, 8, 8, 0.17))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#080808', GradientType=0 );
  height: 38px;
  margin-right: 5px;
  width: 78px;
  float: left;
  border-radius: 0.25rem;
}
.formdd_m select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(../../public/images/ic_expand_more.svg);
  background-repeat: no-repeat;
  background-position: right 5px top 10px;
  background-color: transparent;
}

.formdd_y {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(8, 8, 8, 0.17))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(8, 8, 8, 0.17) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#080808', GradientType=0 );
  height: 38px;
  width: 88px;
  float: right;
  border-radius: 0.25rem;
}
.formdd_y select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  float: right;
  background-image: url(../../public/images/ic_expand_more.svg);
  background-repeat: no-repeat;
  background-position: right 5px top 10px;
  background-color: transparent;
}
.form-dd {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  float: right;
  background-image: url(./../../public/images/ic_expand_more_blue.svg);
  background-repeat: no-repeat;
  background-position: right 15px top 15px;
  background-color: transparent;
  font-size: 18px !important;
}
.btn-charge {
  background-color: $btn-charge-bg-color;
  color: $btn-charge-color;
  border: none;
  outline: none;
  box-shadow: none;
  height: 44px;
}
.btn-complete-transaction {
  background-color: $btn-complete-bg-color;
  color: $btn-complete-color;
  border: none;
  outline: none;
  box-shadow: none;
  height: 44px;
}
