@import "./../../variable.scss";
.powered-by {
  text-align: center;
  width: 100%;
  float: left;
  p {
    font-weight: normal;
    line-height: 14px;
    text-align: right;
    color: $text-powerdby-normal;
    font-size: 8px;
    margin: 5px;
  }
  a {
    font-size: 10px;
  }
  .text-light-blue-bold {
    color: $text-powerdby-light;
    font-size: 10px;
    font-family: $font-700;
  }
  .text-dark-blue-bold {
    color: $text-powerdby-normal;
    font-size: 10px;
    font-family: $font-700;
  }
}
