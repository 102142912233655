/* fonts */
$font-700: "museo_sans700";
$font-300: "museo_sans300";
$font-500: "museo_sans500";

/*Arabic fonts */

$font-arabic-300: "GESSTwo-300";
$font-arabic-700:"GESSTwo-bold";

/* main screen  */
$color-white: #fefefe;
$scroll-background: rgba(0, 0, 0, .2);

/* new section for variable start */
/* chatbot */
$main-chat-shadow: rgba(0, 0, 0, 0.16);
$main-chat-bg: #F5F5F5;
$bot-toggle-bg: #97005E;
/* Initial Screen */
$cover-pattern-bg: #97005E;
$cover-pattern-text-color:#ffffff;
$user-profile-bg: #ffffff;
$user-profile-offline-bg:#e1dfdf;
$color-avatar-title: #97005E;
$color-avatar-title-offline: #777777;
$status-online-bg: #00ff6c;
$status-offline-bg: #D3D3D3;
$body-text-normal: #97005E;
$term-text-para: #97005E;
$term-text-link: #0084FF;
$btn-start-bg: #97005E;
$btn-start-color: #fefefe;
$darkgray-btn: #C3BEBE;
$darkgray-btn-text-white: #FFF;
$inti-main-text-white: #FFF;
$card-link-btn:#F4F4F4;
/* quick replies */
$btn-quick-reply-bg: #97005E;
$btn-quick-reply-border: rgba(149, 30, 96, 0.7);
$btn-quick-reply-color: #97005E;
$btn-quick-reply-selected-bg: #97005E;
$btn-quick-reply-selected-color: #fff;
/* powered by */
$text-powerdby-normal: #002b43;
$text-powerdby-light: #00B8EC;
/* slider */
$color-card-link: #0084FF;
$arrow-color-blue:#0084FF;
$color-card-link-disabled : #999;    
$slider-arrow-shadow:rgba(0,0,0,0.16);
$color-card-link: #0084FF;
/* chat screen */
$chat-header-bg: #97005E;
$chat-header-color: #fff;
$chat-header-botname-color: #FFF;
$chat-body-bg: #ffffff;
$chat-body-bot-msg-color: #646464;
$chat-body-bot-msg-bg: #F2F4F4;
$chat-body-msg-shadow: rgba(0, 0, 0, 0.16);
$chat-body-user-msg-color: #fff;
$chat-body-user-msg-bg: #97005E;
$type-msg-bg: #F2F4F4;
$write-msg-bg: rgba(0, 0, 0, 0);
$write-msg-input-color: #4c4c4c;
$write-msg-color: #bab7b7;
$loader-light-bg: #c9cbcb;
$loader-mobi-primary: #20cef5;
$loader-mobi-primary-dark: #20cef5;
$ti-color-bg: red;
$chat-msg-time-color: #777777;
$doctor-subtitle-text: #777777;

/* form */
$form-tick-green: #4ec794;
$form-tick-blue: #97005E;
$form-bg-color: #fff;
$form-shadow: #ccc;
$form-lbl-color: #000;
$form-lbl-input-color: #000;
$form-steps-bg-color: #d3d3d3;
$form-steps-color: #8f2268;
$form-steps-active: #002b43;
$form-btn-submit-bg: #97005E;
$form-btn-submit-color: #fff;
/* form pay al-alain */
$form-pay-bg: white;
$form-pay-control-border:rgba(210, 210, 210, 0.7);
$secure-text-color: #707070;
$form-pay-ip-color: #000;
$pay-form-lbl-color: #04578d;
$form-border:rgba(112, 112, 112, 0.7);

$humburger-menu-pop-arrow: #707070;
$btn-charge-bg-color: #f2f2f2;
$btn-charge-color: #2c80b5;
$btn-complete-bg-color: #2c80b5;
$btn-complete-color: #f2f2f2;

/* search form */
$search-fill-color:#F7F0F4;
$specialization-list-border-color:rgba(112, 112, 112, 0.7);
$specialization-list-text: #8f2268;
$doctor-name-text:#8f2268;

/* calendar start */
$calender-bg-color:#2C80B5;
$calender-border-color:rgba(44, 128, 181, 0.7);
$calender-header-border-color:rgba(44, 128, 181, 0.7);
$calender-color:#97005E;
$calender-selected-color:#fff;
$calender-selected-bg-color:#97005E;
$calender-month-bg-color:#97005E;
$calender-icon: "/../../public/images/";
/* list-section */
$list-section-title:#000;
$list-section-detail:#000;
$list-section-btn:#0084FF;

/* Range Slider */
$slider-effect-background-color: #ffffff;
$slider-web-background-color: #97005E;

/* map image border color */
$map-img-border-color: rgba(149, 30, 96, 0.7);

/* responsive brackpoint for height */
// $break-laptop: 800px;
$break-laptop:600px;

/* responsive brackpoint for width */
$break-mobile: 500px;

/* responsive brackpoint for width specially for cards */
$break-cards: 370px;

/* range slider */