@import "./../../variable.scss";

// SASS variable for media query breakpoint
$breakpoint-desktop: 992px;

// Resetting default styles
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-flex;
}

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
  width: 100%;
  float: left;
  display: table;
  height: 350px;
  direction: ltr;
}
.carousel__slides {
  padding-left: 50px;
}

// Carousel slides
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: inline;
  max-width: 900px;
  list-style-type: none;
  text-align: center;
  padding-right: 10px;

  @media (max-width: 991px) {
    padding-right: 10px;
    // padding-left: 50px; 
  }

  &--active {
    display: block;
  }
}

// Content of slides
.carousel-slide__content {
  margin-bottom: 19px;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  font-size: 16px;

  @media (max-width: $breakpoint-desktop - 1px) {
    font-size: 18px;
  }
}

.carousel-slide__author,
.carousel-slide__source {
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;

  @media (min-width: $breakpoint-desktop) {
    font-size: 16px;
  }
}

.carousel-slide__source {
  font-style: italic;
  color: #888;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  // top: 50%;
  top: 118px;
  display: block;
  color:#000;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  z-index: 1;
  background: transparent;
  &:focus {
    outline: 0;
  }

  &--left {
    left: 10px;
    width: 30px;
    height: 30px;
    background: #FFF;
    opacity: 1;
    -webkit-box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    -moz-box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    font-size: 12px;
    @media screen and (max-width: $break-mobile) {
      left: 20px;
    }
  }

  &--right {
    right: 234px;
    width: 30px;
    height: 30px;
    background: #FFF;
    opacity: 1;
    -webkit-box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    -moz-box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    box-shadow: 0px 3px 6px 0px $slider-arrow-shadow;
    font-size: 12px;

    @media screen and (max-width: $break-mobile) {
      right: 270px;
    }
    @media screen and (max-width: $break-cards) {
      right: 230px;
    }
  }

  
}
.carousel__arrow.active {
  color:$arrow-color-blue;
}


// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}

.carousel__indicator {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #111;
  cursor: pointer;
  opacity: .15;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:hover {
    opacity: .5;
  }

  &--active {
    &,
    &:hover {
      opacity: .75;
    }
  }
}

.card-body {
  padding: 0px;
  .card-title {
    font-weight: 700;
  }
  .cardlinks {
    font-weight: 700;
  }
  p {
    font-size: 14px;    
    padding:0 10px;
    margin-bottom:0px;
  }
  .list-group-item{
    border-top:1px solid #0084FF;
  }
}
.list-group.list-group-flush {
  min-height: 140px; 
  padding: 0px 10px 10px 10px;
  border-radius: 7px;
}
.card-img {
  background-position: center;
  background-size: cover;
}