@import "./../../variable.scss";
.btn-bbl {
  background-color: $btn-quick-reply-selected-bg;
  border-radius: 40px;
  color: $btn-quick-reply-selected-color;
  white-space: normal;
}
.btn-bbl:hover {
  background-color: $btn-quick-reply-selected-bg;
  border: 1px solid $btn-quick-reply-border;
  border-radius: 40px;
  color: $btn-quick-reply-selected-color;
}
.btn-border-bbl {
  background-color: transparent;
  border: 1px solid $btn-quick-reply-border;
  border-radius: 40px;
  color: $btn-quick-reply-color;
  white-space: normal;
}
.btn-border-bbl:hover {
  border: 1px solid $btn-quick-reply-border;
  background-color: $btn-quick-reply-bg;
  border-radius: 40px;
  color: $btn-quick-reply-selected-color;
}

.btn-confirm{
  background-color: transparent;
  border: 1px solid $btn-quick-reply-border;
  border-radius: 40px;
  color: $btn-quick-reply-color;
  white-space: normal;
  &:focus {
    border: 1px solid $btn-quick-reply-border;    
  }  
}
.general-img {
  border: 1px solid $map-img-border-color;
  max-width: 100%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  height: 150px;
}