@import "./../../variable.scss";

.main-chat-box {
  // width: 400px;
  width: 380px;
  box-shadow: 0px 3px 6px 0px $main-chat-shadow;
  overflow: hidden;
  background: $main-chat-bg;
  position: absolute;
  right: 20px;
  bottom: 20px;
  // bottom: 80px;
  border-radius: 8px;
  @media screen and (max-width: $break-mobile) {
    /*
    width: 340px;
    right: 10px;
    max-width: 94%;
    */
    border-radius: 0px;
    width: 100%;
    max-width: 100%;
    // position: absolute;
    height: 100%;
    bottom: 0;
    margin: 0;
    right: 0;
  }
  @media screen and (max-height: $break-laptop) {
    // bottom: 100px;
  }
}
.bottom-toggle {
  width: 52px;
  height: 52px;
  background: $bot-toggle-bg;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  @media screen and (max-height: $break-laptop) {
    width: 60px;
    height: 60px;
  }
  @media screen and (max-width: $break-mobile) {
   /*
    width: 30px;
    height: 30px;
   */
    bottom: 5px;
    right: 5px;
  }
}
.icon_ic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent !important;
  background-size: 60%;
  background-position: center center;
}
.icon_ic:hover {
  cursor: pointer;
}
.icon_ic.close_ic {
  background-image: url(./../../public/images/close.png);
  background-color: transparent !important;
}
.icon_ic.chat_ic {
  background-image: url(./../../public/images/chat.png);
  background-color: transparent !important;
}


@media screen and (max-width: $break-mobile) {
  .user-profile-male-b {
    left: calc(50% - 65px) !important;
  }
  .status-online-b{
    width: 10px;
    height: 10px;
  }
  .cover-pattern-b
    h3{
      font-size: 16px;
    }
   h3 > img  
  {
    width: 125px;
  }
}