@import "./../../variable.scss";

.specialization-main {
  background-color: $form-bg-color;
  width: 86%;
  float: right;
	border-radius: 10px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
	margin-bottom: 10px;
	.search-fill {
		position: relative;
		width: 90%;
		margin: 5%;
		padding-top: 0 !important;
		.form-control {
			background-color: $search-fill-color;
			border: none;
			height: 40px;
			padding-right: 40px;
		}
		.search-ic {
			position: absolute;
			right: 10px;
			top: 5px !important;
				img {
					width: 20px;
				}
				@media screen and (max-width: $break-mobile) {
					top: 17px;
				}
			}
  	}
    .specialization-list{
			width: 80%;
			height: 200px;
			overflow: auto;
			overflow-x: hidden;
			margin: 0 auto;
			text-align: left;
			margin-left: 10%;
			margin-right: 10%;
			ul {
				display: inline;
				text-align: left;
				li {
					text-align: left;
					border-bottom: 1px solid $specialization-list-border-color;
					padding-top: 10px;
					padding-bottom: 10px;
					span {
						width: 100%;
						text-align: left;
						font-size: 14px;
						color: $specialization-list-text;
						cursor: pointer;
					}
				}
			}
    }
}
// .specialization-main .specialization-list ul li{
// 	display:none;
// }

.noneclaas{
	display: none;
}

.disabled{
    pointer-events:none;
    opacity:0.7;
}