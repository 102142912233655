@import "./../../variable.scss";
.Row
{
    display: table;
}
.Column
{
    display: table-cell;
    padding-right: 10px;
}
.multiselect {
    width: 200px;
  }
  
  .selectBox {
    position: relative;
  }
  
  .selectBox select {
    width: 100%;
    font-weight: bold;
  }
  
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  #checkboxes {
    display: none;
    border: 1px #dadada solid;
  }
  
  #checkboxes label {
    display: block;
  }
.radio-input{
    font-size: 15px;
    color: #000;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 5px;
}

.radio-label {
  font-weight: normal;
  font-size: 10px;
  line-height: 26px;
  text-align: left;
  color: #000;
  margin-right: 0 !important;
  float: left;
  width: 50% !important;
}
.select-opt {
-webkit-appearance: none;
background: transparent;
color: #8a8a8a;
padding: 0 15px;
font-weight: normal !important;
font-size: 14px;
margin-bottom: 2px;
border-radius: 4px;
}
#checkboxes {
  padding: 5px 15px;
  max-height: 150px;
  overflow-y: scroll;
}
#checkboxes label, .form-main input, #checkboxes p {
  display: inline-block !important;
  vertical-align: middle;
}
#checkboxes p {
  margin: 0;
}
.input-opt {
  margin-right: 8px !important;
  width: 18px !important;
  height: 18px !important;
}
.btn-wrapper, .btn-main-block {
  display: inline-block;
}
.main-right-m {
  margin-right: 5px;
}
.main-left-m {
  margin-left: 5px;
}
