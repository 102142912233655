@import "./../../variable.scss";
.card {
  border-radius: 1rem;
  overflow: hidden;
  width: 254px;
  border-left:none; 
  border-right:none; 
  border-top:none; 
  border:none;
  box-shadow: 0px 3px 6px 0px #cccccc;
  // border-bottom: 1px solid #0084FF;
  // for top align cards

  @media screen and (max-width: $break-cards) {
    width: 69vw;
  }

  // .list-group.list-group-flush{
  //   border-left: 1px solid #0084FF;
  //   margin: -1px;
  //   border-right: 1px solid #0084FF;
  // }
  .cardlinks {
    color: $color-card-link;
    font-size: 14px;
    text-align: center;
    font-family: $font-500;
    border-radius: 7px;
    background-color:$card-link-btn;
    margin-top: 5px;
  }
  .cardlinks.disabled {
    color: $color-card-link-disabled !important;
  }
  .card-body {
    text-align: left;
    color: #000;
    padding: 0px;
    h5{
      padding: 10px 10px 0px 10px
    }
    .list-group{
      // border: 1px solid #707070;
      border-top:none;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow: hidden;
      box-shadow: none;
        li
        {
          // border-top: 1px solid #707070;
          &:last-child{
            border-bottom: none;
          }
        }
    }
  }
  .card-title {
    font-size: 12px;
    font-family: $font-700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;    
  }
  .card-img {
    height: 134px;
    overflow: hidden;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    // border: 1px solid #707070;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }  
  .card-header{
      // border: 1px solid #707070;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0px 0px 5px 0px;
      background-color: transparent;
      border-bottom: none;
      
      text-align: center;
  }
   
}
.widthset{
    display: inline-block;
    width: auto;
}
.a{
    color: white !important;
}

a:hover { 
    color: white !important;
  }