@import "./../../variable.scss";
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.chat-converstion {
  width: 100%;
  height: 580px;
  background-image: url(./../../public/images/bg_white_pattern.png);
  @media screen and (max-height: $break-laptop) {
    height: unset;
  }
  @media screen and (max-width: $break-mobile) {
    height: unset;
    float: none;
  }
}
.chat-box-header {
  background: $chat-header-bg;
  width: 100%;
  height: 50px;
  color: $chat-header-color;
  font-size: 20px;
  padding-top: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (max-width: $break-mobile) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  position: absolute !important;
  top: 10px;
  z-index: 9;
  .headeravtar {
    width: 32px;
    height: 32px;
    background-color: $user-profile-bg;
    border-radius: 100%;
    position: absolute;
    top: 9px;
    left: 12px;
    img {
      width: 100%;
      //margin-top: -6px;
      display: flex;
    }
  }
}
.bot-name {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $chat-header-botname-color;
  text-align: left;
  font-weight: 700;
  position: absolute;
  left: 44px;
}
.box-header-ic {
  float: none !important;
  position: absolute;
  right: 16px;
  top: 9px;
  &:hover {
    cursor: pointer;
  }
}
.chat-body {
  width: 100%;
  float: left;
  // padding-top: 15px;
  box-sizing: border-box;
  background-color: $chat-body-bg;
  padding-top: 50px;
  @media screen and (max-width: $break-mobile) {
    height: 102vh;
  }
}
.agent-parent-container {
  padding-top: 15px;
}
.live-agent-container {
  padding-top: 0px;
  height: 80vh !important;
  height: -moz-available;
  // height: -webkit-fill-available;
  height: stretch;
  border: 0px;
  /* padding-bottom: 12%; */
}
.recent-heading {
  float: left;
  width: 40%;
}
.bot {
  float: left;
  margin-bottom: 5px;
  width: 98%;
  text-align: left;
  margin: 0 1% 0px 1%;
  position: relative;
  .bot-avatar {
    display: inline-block;
    position: absolute;
    bottom: 12px;
    border-radius: 100%;
    background: $user-profile-bg;
    width: 25px;
    height: 25px;
    left: 10px;
    img {
      display: inline-block;
      width: 25px;
      margin-top: -3px;
    }
  }
  .bot-msg {
    display: inline-block;
    padding: 0 0 0 5px;
    vertical-align: top;
    word-break: break-word;
    border-bottom-left-radius: 0;
    /* width: 83%; */
    max-width: calc(100% - 40px);
    margin-left: 40px;
    position: relative;
    p {
      font-size: 14px;
      padding: 5px 10px 5px 12px;
      width: 100%;
      border-radius: 10px;
      background: $chat-body-bot-msg-bg;
      box-shadow: 0px 0px 0px $chat-body-msg-shadow;
      margin-bottom: 10px;
      overflow: auto;
      text-align: left;
      border-bottom-left-radius: 0;
    }
    .chat-time {
      // position: absolute;
      left: 10px;
      font-size: 12px;
      color: $chat-msg-time-color;
    }
  }
}
.user-msg {
  overflow: hidden;
  margin: 0px 5px 0px 0px;
  padding-bottom: 5px;
  max-width: 85%;
  float: right;
  clear: right;
  position: relative;
  min-width: 50px;
  p {
    font-size: 14px;
    margin: 0;
    color: $chat-body-user-msg-color;
    padding: 5px 10px 5px 12px;
    width: 100%;
    border-radius: 10px;
    background: $chat-body-user-msg-bg;
    box-shadow: 0px 0px 0px $chat-body-msg-shadow;
    text-align: left;
    margin-bottom: 5px;
    border-bottom-right-radius: 0;
  }
  .chat-time {
    // position: absolute;
    right: 0px;
    text-align: right;
    font-size: 12px;
    color: $chat-msg-time-color;
  }
}
.type-msg {
  position: relative;
  height: 42px;
  border-radius: 7px;
  background: $type-msg-bg;
  // box-shadow: 0px 3px 6px $chat-body-msg-shadow;

  width: 82%;
  margin-left: 8px;
  float: left;
  .input-msg-write {
    input {
      background: $write-msg-bg none repeat scroll 0 0;
      border: medium none;
      color: $write-msg-input-color;
      font-size: 12px;
      min-height: 44px;
      width: 85%;
      padding: 0 10px;
      float: left;
      &::placeholder {
        font-style: italic;
      }
    }
    .write-msg {
      color: $write-msg-color;
    }

    .btn-operation {
      border: none;
      cursor: pointer;
      font-size: 17px;
      height: 33px;
      position: absolute;
    }
    .btn-send {
      width: 20px;
      margin-top: 7px;
    }
    .btn-attachment {
      right: 7px;
      top: 5px;
      width: 23px;
    }
    .btn-gif {
      right: 38px;
      top: 6px;
      width: 34px;
    }
    .btn-smile {
      right: 81px;
      top: 5px;
      width: 22px;
    }
    .btn-camera {
      right: 112px;
      top: 5px;
      width: 22px;
    }
  }
}

.menu_ic {
  width: 11%;
  float: left;
  cursor: pointer;
  img {
    width: 20px;
    padding-top: 31px;
    margin-left: 7px;
  }
}
.status-online {
  background-color: $status-online-bg;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.chat-logs {
  height: 437px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  @media screen and (max-height: $break-laptop) {
    max-height: 100vh;
    float: none;
    // height: 44vh;
    height: 61vh;
  }
  @media screen and (max-width: $break-mobile) {
    max-height: 100%;
    float: none;
    //height: 50vh;
    // height: calc(100vh - 145px);
    height: calc(100% - 100px);

    // position: absolute;
    bottom: 0;
    width: 100%;
    padding-top: 50px;
  }
  // .message-loader {
  //   align-items: center;
  //   border: 1px solid $loader-light-bg;
  //   border-radius: 15px;
  //   display: flex;
  //   height: 20px;
  //   justify-content: space-between;
  //   padding: 0 5px;
  //   position: relative;
  //   width: 45px;
  //   .loader-bar {
  //     background: $loader-light-bg;
  //     border-radius: 5px;
  //     height: 8px;
  //     position: relative;
  //     width: 8px;
  //     &:first-of-type {
  //       animation: chat-typing-indicator 1.5s infinite;
  //     }
  //     &:nth-of-type(2) {
  //       animation: chat-typing-indicator 1.5s infinite 0.2s;
  //     }
  //     &:nth-of-type(3) {
  //       animation: chat-typing-indicator 1.5s infinite 0.4s;
  //     }
  //   }

  @keyframes bulge {
    50% {
      transform: scale(1.05);
    }
  }
}

//   @keyframes chat-typing-bar {
//     0% {
//       width: 8px;
//     }
//     25% {
//       width: calc(100% - 10px);
//     }
//     50% {
//       width: 8px;
//     }
//     75% {
//       width: calc(100% - 10px);
//     }
//     100% {
//       width: 8px;
//     }

//     25% {
//       left: 5px;
//     }
//     50% {
//       left: calc(100% - 13px);
//     }
//     75% {
//       left: 5px;
//     }
//     0% {
//       background: $loader-mobi-primary;
//     }
//     50% {
//       background: $loader-mobi-primary-dark;
//     }
//     100% {
//       background: $loader-mobi-primary;
//     }
//   }

//   @keyframes chat-typing-indicator {
//     0% {
//       top: 0;
//     }
//     6.25% {
//       top: -1px;
//     }
//     12.5% {
//       top: 0;
//     }
//     18.75% {
//       top: 1px;
//     }
//     25% {
//       top: 0;
//     }
//     12.5% {
//       background: $loader-mobi-primary;
//     }
//   }
// }

/* load_avtar */
.load_avtar {
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding: 10px 30px;
  box-sizing: border-box;
  border-radius: 30px;
  img {
    height: 45vh;
    // height: 320px;
    @media screen and (max-height: $break-laptop) {
      // height: 270px;
    }
    @media screen and (max-height: $break-mobile) {
      // height: 260px;
    }
    margin-right: -10px;
  }
}

@keyframes blink {
  25% {
    opacity: 1;
  }
}

.bot-typeing {
  padding-bottom: 5px;
  height: 20px;
}

.bot-typer-name {
  display: table-cell;
  color: #777777;
  font-size: 12px;
  padding-right: 5px;
}
.typing-indicator {
  // background-color: $ti-color-bg;
  will-change: transform;
  width: auto;
  border-radius: 50px;
  // padding: 10px;
  display: table;
  margin: 0 auto;
  position: relative;
  margin-left: 10px !important;
  float: left;
  // animation: 2s bulge infinite ease-out;
  // &::before,
  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -2px;
  //   left: -1px;
  //   height: 10px;
  //   width: 10px;
  //   border-radius: 50%;
  //   background-color: $ti-color-bg;
  // }
  &::after {
    height: 5px;
    width: 5px;
    bottom: -5px;
    left: -5px;
  }
  span {
    height: 5px;
    width: 5px;
    float: left;
    margin: 0 1px;
    background-color: #b9b9b9;
    display: block;
    border-radius: 50%;
    opacity: 0.4;

    @for $i from 3 through 1 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * 0.3333s);
      }
    }
  }
}

.bottom-part {
  position: relative;
  width: 100%;
  @media screen and (max-width: $break-mobile) {
    position: fixed;
    bottom: 0;
    background: #fff;
  }
}
.font {
  font-family: "Poppins-SemiBold, Poppins";
  width: 30px !important;
  padding-top: 25px !important;
  margin-left: 7px !important;
}

.visibility {
  visibility: hidden;
}

.leave-live-agent {
  position: absolute;
  font-size: 16px;
  padding: 1px 14px;
  border-radius: 10px;
}
