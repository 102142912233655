@import "./../../variable.scss";
.fill_ic {
  padding: 0 8px;
  border: none;
}
.tick_ic_green {
  background-color: $form-tick-green;
  width: 40px;
}
.arrow_ic_blue {
  background-color: $form-tick-blue;
  width: 40px;
  text-align: center;
  padding-left: 15px;
}
.form-main {
  background-color: $form-bg-color;
  box-shadow: 0px 0px 0px $form-shadow;
  float: right;
  // width: 97%;
  width: calc(100% - 55px);
  margin: 0 1.5%;
  border-radius: 10px;
  margin-bottom: 10px;

  .btn-text-white {
    background: $darkgray-btn;
    color: $darkgray-btn-text-white;
  }
}
.form-main label {
  font-weight: normal;
  font-size: 10px;
  line-height: 26px;
  text-align: left;
  color: $form-lbl-color;
  width: 100%;
  margin: 0;
}
.form-main input {
  font-size: 15px;
  color: $form-lbl-input-color;
}
.steps {
  width: 100%;
  float: left;

  ul {
    width: 100%;
    li {
      width: 24%;
      background-color: $form-steps-bg-color;
      height: 5px;
      margin-left: 2px;
      float: left;
    }
  }
}
.steps-digit {
  color: $form-steps-color;
  font-size: 12px;
}
.steps ul li:last-child {
  width: 25%;
  margin-right: 0;
}
.steps ul li:hover {
  background-color: $form-steps-active;
  cursor: pointer;
}
.steps ul li.active {
  background-color: $form-steps-active;
}

.btn-blue {
  border-radius: 7px;
  background: $form-btn-submit-bg !important;
  color: $form-btn-submit-color !important;
}
.btn-form-link {
  color: $form-btn-submit-bg;
  font-size: 11px;
}
.form-header {
  background-image: url(./../../public/images/header_line.svg);
  background-repeat: no-repeat;
  padding-bottom: 10px;
  overflow: hidden;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 10px;
}

.fill-otp {
  border: 0;
  border: 1px solid $form-border;
}

.otp-top-line {
  float: left;
  .form-control {
    max-width: 15% !important;
    margin: 0 auto !important;
    margin-top: 20px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
}

.form-control::-webkit-input-placeholder {
  color: #dfdfdf;
}

.form-control::-moz-placeholder {
  color: #dfdfdf;
}

.form-control:-ms-input-placeholder {
  color: #dfdfdf;
}

.form-control::-ms-input-placeholder {
  color: #dfdfdf;
}

.form-control::placeholder {
  color: #dfdfdf;
}
/* datepicker css old start *

.react-datepicker__input-container {
  display: inline;
  position: relative;
  width: 100%;
}
.react-datepicker-wrapper {
  display: inline-block;
  width: 240px;
  @media screen and (max-width: $break-mobile) {
    width: 180px;
  }
}
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: $calender-color !important;
}
.form-control:focus {
  border-color: $calender-border-color !important;
}
.react-datepicker__navigation--next {
  border-left-color: $calender-bg-color !important;
}
.react-datepicker__navigation--previous {
  border-right-color: $calender-bg-color !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 1.3rem !important;
  background-color: $calender-bg-color !important;
  color: $calender-selected-color !important;
}
.react-datepicker__month-container {
  background-color: $calender-month-bg-color;
}
.react-datepicker {
  border: none !important;
  box-shadow: 0px 0px 10px #ccc !important;
}
.react-datepicker__header {
  border-bottom: none !important;
}
date picler old css end */
/* day picker start 
.DayPickerInput input {
  background-image: url(./../../public/images/cal_ic_blue.svg);
  background-repeat: no-repeat;
  padding-left: 40px;
  background-size: 20px;
  background-position: 10px;
  border: 2px solid #dadada;
  border-radius: 7px;
}
*/
/*
.DayPicker-Caption {
  color: $calender-color !important;
}

.DayPicker-Weekday {
  color: $calender-color !important;
}

.DayPicker-Day--selected {
  background-color: $calender-selected-bg-color !important;
  color: $calender-selected-color !important;
}

.DayPicker-Day--today {
  color: $calender-color !important ;
}
.DayPicker-Day {
  color: $calender-color ;
}
.DayPicker-Day--disabled  {
  color: #DCE0E0 !important;
}

.DayPickerInput {
  display: inline-block;
    input {
      width: 243px;
      
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #4c4c4c;
      font-size: 15px;
      min-height: 38px;
      padding: 0 10px;
      background-image: url("./../../public/images/cal_ic.svg");
      background-repeat: no-repeat;
      padding-left: 40px;
      background-size: 20px;
      background-position: 10px;
      @media screen and (max-width: $break-mobile) {
        width: 180px;
      }      
    }
  }
  /* day picker css end */

.datedate {
  display: inline-block;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 38px;
  padding: 0 10px;
  background-image: url("./../../public/images/cal_ic.svg");
  background-repeat: no-repeat;
  padding-left: 40px;
  background-size: 20px;
  background-position: 10px;
  width: 223px !important;
  @media screen and (max-width: $break-mobile) {
    width: 180px !important;
  }
  @media screen and (max-width: 358px) and (min-width: 320px) {
    width: 130px !important;
  }
}
.rc-calendar-month-panel-selected-cell
  .rc-calendar-month-panel-month
  .rc-calendar-footer-btn {
  display: none;
}
.rc-calendar-selected-date .rc-calendar-date {
  background: #97005E !important;
  color: #fff !important;
}

.rc-calendar-last-month-cell .rc-calendar-date,
.rc-calendar-next-month-btn-day .rc-calendar-date {
  color: #bbb;
}
.rc-calendar-year-select:hover,
.rc-calendar-month-select:hover,
.rc-calendar-day-select:hover {
  cursor: pointer;
  color: #97005E;
}
.rc-calendar-disabled-cell .rc-calendar-date {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f3f3f3;
  border-radius: 0;
  width: auto;
}
.rc-calendar-disabled-cell .rc-calendar-year {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f3f3f3;
  border-radius: 0;
  width: auto;
}
/* day picker css end */
.rc-calendar-year-select:hover,
.rc-calendar-month-select:hover,
.rc-calendar-day-select:hover {
  cursor: pointer;
  color: #97005E !important;
}
.rc-calendar-date:hover {
  background: #c3bebe;
  cursor: pointer;
}

.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
  background: #97005E !important;
  color: #fff !important;
}
.rc-calendar-month-panel-year-select-content {
  display: none;
}
.rc-calendar-month-panel-year-select-arrow {
  display: none;
}
.rc-calendar-column-header .rc-calendar-column-header-inner {
  font-weight: normal;
}
.rc-calendar-header .rc-calendar-next-month-btn {
  position: absolute;
  right: 25px;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}

.rc-calendar-picker {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1000;
}

.rc-calendar-body {
  padding: 9px 10px 10px;
  height: 217px;
}

.rc-calendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
}
.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
  background: #97005E !important;
  color: #fff;
}
.rc-calendar-year-panel-year {
  display: block;
  width: 46px;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  height: 36px;
  padding: 0;
  background: transparent;
  line-height: 36px;
  text-align: center;
}
.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}

.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 0;
}

.rc-calendar-year-panel-body {
  -ms-flex: 1;
  flex: 1;
  padding: 9px 10px 10px;
}

.rc-calendar-month-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  position: absolute;
  outline: none;
}

.rc-calendar-year-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  position: absolute;
  outline: none;
}

.rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
  width: 180px;
}
.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-year-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-decade-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  position: absolute;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade {
  background: #97005E !important;
  color: #fff;
}

.rc-calendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
}

.rc-calendar-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  text-align: center;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
.rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
  user-select: none;
  -webkit-user-select: none;
  color: rgba(0, 0, 0, 0.25) !important;
}
.rc-calendar-year-select,
.rc-calendar-month-select,
.rc-calendar-day-select {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  padding: 0 8px;
  line-height: 34px;
  .rc-calendar-last-month-cell .rc-calendar-date,
  .rc-calendar-next-month-btn-day .rc-calendar-date {
    color: #bbb;
  }
  .rc-calendar-year-select:hover,
  .rc-calendar-month-select:hover,
  .rc-calendar-day-select:hover {
    cursor: pointer;
    color: #97005E;
  }
  .rc-calendar-disabled-cell .rc-calendar-date {
    cursor: not-allowed;
    color: #bcbcbc;
    background: #f3f3f3;
    border-radius: 0;
    width: auto;
  }
  .rc-calendar-disabled-cell .rc-calendar-year {
    cursor: not-allowed;
    color: #bcbcbc;
    background: #f3f3f3;
    border-radius: 0;
    width: auto;
  }
  /* day picker css end */
  .rc-calendar-year-select:hover,
  .rc-calendar-month-select:hover,
  .rc-calendar-day-select:hover {
    cursor: pointer;
    color: #97005E !important;
  }
  .rc-calendar-date:hover {
    background: #c3bebe;
    cursor: pointer;
  }

  .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
    background: #97005E !important;
    color: #fff;
  }
  .rc-calendar-month-panel-year-select-content {
    display: none;
  }
  .rc-calendar-month-panel-year-select-arrow {
    display: none;
  }
  .rc-calendar-column-header .rc-calendar-column-header-inner {
    font-weight: normal;
  }
  .rc-calendar-header .rc-calendar-next-month-btn {
    position: absolute;
    right: 25px;
  }
  .rc-calendar-prev-month-btn,
  .rc-calendar-next-month-btn,
  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #999;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 34px;
  }

  .rc-calendar-picker {
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1000;
  }

  .rc-calendar-body {
    padding: 9px 10px 10px;
    height: 217px;
  }

  .rc-calendar table {
    border-collapse: collapse;
    max-width: 100%;
    background-color: transparent;
    width: 100%;
  }
  .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
    background: #97005E !important;
    color: #fff;
  }
  .rc-calendar-year-panel-year {
    display: block;
    width: 46px;
    margin: 0 auto;
    color: #666;
    border-radius: 4px 4px;
    height: 36px;
    padding: 0;
    background: transparent;
    line-height: 36px;
    text-align: center;
  }
  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }

  .rc-calendar-year-panel-prev-decade-btn,
  .rc-calendar-year-panel-next-decade-btn {
    position: absolute;
    top: 0;
  }

  .rc-calendar-year-panel-body {
    -ms-flex: 1;
    flex: 1;
    padding: 9px 10px 10px;
  }

  .rc-calendar-month-panel {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    z-index: 10;
    position: absolute;
    outline: none;
  }

  .rc-calendar-year-panel {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    z-index: 10;
    position: absolute;
    outline: none;
  }

  .rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
    width: 180px;
  }
  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-year-panel-header {
    padding: 0 10px;
    height: 34px;
    line-height: 30px;
    position: relative;
    text-align: center;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ccc;
  }
  .rc-calendar-decade-panel {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    z-index: 10;
    position: absolute;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade {
    background: #97005E !important;
    color: #fff;
  }

  .rc-calendar table {
    border-collapse: collapse;
    max-width: 100%;
    background-color: transparent;
    width: 100%;
  }

  .rc-calendar-header {
    padding: 0 10px;
    height: 34px;
    line-height: 30px;
    text-align: center;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ccc;
  }
  .rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
  .rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
    user-select: none;
    -webkit-user-select: none;
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .rc-calendar-year-select,
  .rc-calendar-month-select,
  .rc-calendar-day-select {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #666;
    padding: 0 8px;
    line-height: 34px;
  }
  .rc-calendar-year-select,
  .rc-calendar-month-select,
  .rc-calendar-day-select {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #666;
    padding: 0 8px;
    line-height: 34px;
  }

  .rc-calendar {
    position: relative;
    outline: none;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
    width: 253px;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
  }

  .rc-calendar-date {
    display: block;
    margin: 0 auto;
    color: #666;
    border-radius: 4px 4px;
    width: 26px;
    height: 26px;
    padding: 0;
    background: transparent;
    line-height: 26px;
    text-align: center;
  }

  .rc-calendar-prev-month-btn,
  .rc-calendar-next-month-btn,
  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #999;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 34px;
  }
  .rc-calendar-next-year-btn {
    right: 0;
  }

  .rc-calendar-header .rc-calendar-prev-month-btn {
    position: absolute;
    left: 25px;
  }
  .rc-calendar-prev-month-btn,
  .rc-calendar-next-month-btn,
  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #999;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 34px;
  }

  .rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
  .rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
    background: #ebfaff;
    cursor: pointer;
  }
  .rc-calendar-year-panel-year:hover {
    background: #ebfaff;
    cursor: pointer;
  }

  .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
    display: block;
    width: 46px;
    margin: 0 auto;
    color: #666;
    border-radius: 4px 4px;
    height: 36px;
    padding: 0;
    background: transparent;
    line-height: 36px;
    text-align: center;
  }
  .rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
    background: #ebfaff;
    cursor: pointer;
  }
  .rc-calendar-year-panel-year:hover {
    background: #ebfaff;
    cursor: pointer;
  }
  .rc-calendar-decade-panel-body {
    -ms-flex: 1;
    flex: 1;
    padding: 9px 10px 10px;
  }

  .rc-calendar table {
    border-collapse: collapse;
    max-width: 100%;
    background-color: transparent;
    width: 100%;
  }

  .rc-calendar-next-year-btn {
    right: 0;
  }
  .rc-calendar-decade-panel-header {
    padding: 0 10px;
    height: 34px;
    line-height: 34px;
    position: relative;
    text-align: center;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ccc;
  }
  .rc-calendar-prev-month-btn,
  .rc-calendar-next-month-btn,
  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #999;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 34px;
  }

  .rc-calendar-decade-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 1px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-decade-panel-prev-century-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0;
  }
  .rc-calendar-decade-panel-prev-century-btn,
  .rc-calendar-decade-panel-next-century-btn {
    position: absolute;
    top: 0;
  }

  .rc-calendar-decade-panel-header {
    padding: 0 10px;
    height: 34px;
    line-height: 34px;
    position: relative;
    text-align: center;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ccc;
  }
  .rc-calendar-input {
    border: 1px solid transparent;
    width: 100%;
    color: #666;
    cursor: text;
    line-height: 1.5;
    outline: 0;
    height: 22px;
  }

  .rc-calendar-year-panel-prev-decade-btn:after {
    content: "\AB";
  }
  .rc-calendar-year-panel-next-decade-btn:after {
    content: "\BB";
  }
  .rc-calendar-prev-year-btn:after {
    content: "\AB";
  }
  .rc-calendar-header .rc-calendar-prev-month-btn:after {
    content: "\2039";
  }
  .rc-calendar-header .rc-calendar-next-month-btn:after {
    content: "\203A";
  }

  .rc-calendar-next-year-btn:after {
    content: "\BB";
  }

  .rc-calendar-prev-year-btn {
    left: 0;
  }
  .rc-calendar-prev-month-btn,
  .rc-calendar-next-month-btn,
  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    position: absolute;
    top: 0;
    cursor: pointer;
    color: #999;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
      "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 34px;
  }

  .rc-calendar-prev-year-btn:after {
    content: "\AB";
  }

  .rc-calendar-year-panel-decade-select-arrow {
    display: none;
  }

  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-year-panel-prev-decade-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0;
  }
  .rc-calendar-year-panel-prev-decade-btn,
  .rc-calendar-year-panel-next-decade-btn {
    position: absolute;
    top: 0;
  }

  .rc-calendar-year-panel-prev-decade-btn:after {
    content: "\AB";
  }

  .rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
    width: 180px;
  }
  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }

  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-year-panel-next-decade-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 0;
  }
  .rc-calendar-year-panel-prev-decade-btn,
  .rc-calendar-year-panel-next-decade-btn {
    position: absolute;
    top: 0;
  }

  .rc-calendar-year-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-year-panel-next-decade-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 0;
  }
  .rc-calendar-year-panel-prev-decade-btn,
  .rc-calendar-year-panel-next-decade-btn {
    position: absolute;
    top: 0;
  }
  .rc-calendar-year-panel-next-decade-btn:after {
    content: "\BB";
  }

  .rc-calendar-decade-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 1px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-decade-panel-prev-century-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0;
  }
  .rc-calendar-decade-panel-prev-century-btn,
  .rc-calendar-decade-panel-next-century-btn {
    position: absolute;
    top: 0;
  }
  .rc-calendar-decade-panel-prev-century-btn:after {
    content: "\AB";
  }
  .rc-calendar-decade-panel-header > a {
    font-weight: bold;
    display: inline-block;
    padding: 1px 5px;
    text-align: center;
    width: 30px;
  }
  .rc-calendar-decade-panel-next-century-btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    right: 0;
  }
  .rc-calendar-decade-panel-prev-century-btn,
  .rc-calendar-decade-panel-next-century-btn {
    position: absolute;
    top: 0;
  }
  .rc-calendar-decade-panel-next-century-btn:after {
    content: "\BB";
  }

  .rc-calendar-clear-btn {
    z-index: 9999;
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 6px;
    margin: 0;
  }
  .rc-calendar-clear-btn:after {
    content: "x";
    font-size: 12px;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    width: 20px;
    transition: color 0.3s ease;
  }

  .rc-calendar-today .rc-calendar-date {
    border: 1px solid #97005E !important;
  }

  .rc-calendar-selected-date .rc-calendar-date:hover {
    background: #97005E;
  }

  .rc-calendar-disabled-cell .rc-calendar-year {
    cursor: not-allowed;
    color: #bcbcbc;
    background: #f3f3f3;
    border-radius: 0;
    width: auto;
  }
  .rc-calendar table,
  .rc-calendar td,
  .rc-calendar th,
  .rc-calendar td {
    border: none;
  }

  .rc-calendar-cell {
    padding: 1px 0;
  }

  .rc-calendar-disabled-cell .rc-calendar-date {
    cursor: not-allowed;
    color: #bcbcbc;
    background: #f3f3f3;
    border-radius: 0;
    width: auto;
  }
  .rc-calendar-date {
    display: block;
    margin: 0 auto;
    color: #666;
    border-radius: 4px 4px;
    width: 26px;
    height: 26px;
    padding: 0;
    background: transparent;
    line-height: 26px;
    text-align: center;
  }
}
.rc-calendar-year-select,
.rc-calendar-month-select,
.rc-calendar-day-select {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  padding: 0 8px;
  line-height: 34px;
}

.rc-calendar {
  position: relative;
  outline: none;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
  width: 253px;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.rc-calendar-date {
  display: block;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  width: 26px;
  height: 26px;
  padding: 0;
  background: transparent;
  line-height: 26px;
  text-align: center;
}

.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}
.rc-calendar-next-year-btn {
  right: 0;
}

.rc-calendar-header .rc-calendar-prev-month-btn {
  position: absolute;
  left: 25px;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}

.rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
.rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25) !important;
}

.rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-year-panel-year:hover {
  background: #ebfaff;
  cursor: pointer;
}

.rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
  display: block;
  width: 46px;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  height: 36px;
  padding: 0;
  background: transparent;
  line-height: 36px;
  text-align: center;
}
.rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-year-panel-year:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-decade-panel-body {
  -ms-flex: 1;
  flex: 1;
  padding: 9px 10px 10px;
}

.rc-calendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
}

.rc-calendar-next-year-btn {
  right: 0;
}
.rc-calendar-decade-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}

.rc-calendar-decade-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 1px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-decade-panel-prev-century-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
}
.rc-calendar-decade-panel-prev-century-btn,
.rc-calendar-decade-panel-next-century-btn {
  position: absolute;
  top: 0;
}

.rc-calendar-decade-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-input {
  border: 1px solid transparent;
  width: 100%;
  color: #666;
  cursor: text;
  line-height: 1.5;
  outline: 0;
  height: 22px;
}

.rc-calendar-year-panel-prev-decade-btn:after {
  content: "\AB";
}
.rc-calendar-year-panel-next-decade-btn:after {
  content: "\BB";
}
.rc-calendar-prev-year-btn:after {
  content: "\AB";
}
.rc-calendar-header .rc-calendar-prev-month-btn:after {
  content: "\2039";
}
.rc-calendar-header .rc-calendar-next-month-btn:after {
  content: "\203A";
}

.rc-calendar-next-year-btn:after {
  content: "\BB";
}

.rc-calendar-prev-year-btn {
  left: 0;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}

.rc-calendar-prev-year-btn:after {
  content: "\AB";
}

.rc-calendar-year-panel-decade-select-arrow {
  display: none;
}

.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-year-panel-prev-decade-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
}
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 0;
}

.rc-calendar-year-panel-prev-decade-btn:after {
  content: "\AB";
}

.rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
  width: 180px;
}
.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}

.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-year-panel-next-decade-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 0;
}
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 0;
}

.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-year-panel-next-decade-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 0;
}
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-year-panel-next-decade-btn:after {
  content: "\BB";
}

.rc-calendar-decade-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 1px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-decade-panel-prev-century-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
}
.rc-calendar-decade-panel-prev-century-btn,
.rc-calendar-decade-panel-next-century-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-decade-panel-prev-century-btn:after {
  content: "\AB";
}
.rc-calendar-decade-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 1px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-decade-panel-next-century-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 0;
}
.rc-calendar-decade-panel-prev-century-btn,
.rc-calendar-decade-panel-next-century-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-decade-panel-next-century-btn:after {
  content: "\BB";
}

.rc-calendar-clear-btn {
  z-index: 9999;
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}
.rc-calendar-clear-btn:after {
  content: "x";
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease;
}

.rc-calendar-today .rc-calendar-date {
  border: 1px solid #97005E !important;
}

.rc-calendar-selected-date .rc-calendar-date:hover {
  background: #97005E;
}

.rc-calendar-disabled-cell .rc-calendar-year {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f3f3f3;
  border-radius: 0;
  width: auto;
}
.rc-calendar table,
.rc-calendar td,
.rc-calendar th,
.rc-calendar td {
  border: none;
}

.rc-calendar-cell {
  padding: 1px 0;
}

.rc-calendar-disabled-cell .rc-calendar-date {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f3f3f3;
  border-radius: 0;
  width: auto;
}
.rc-calendar-date {
  display: block;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  width: 26px;
  height: 26px;
  padding: 0;
  background: transparent;
  line-height: 26px;
  text-align: center;
}
.datefield-sector { 
  input {
    direction: ltr;
    padding:2px;
  }
  .fill_ic{
    height: 38px;
  }
  .year {
    width: 85px !important;
    float: left;
    @media screen and (max-width: $break-mobile) {
      width: 32% !important;
    }
  }
  .info-label {
    width: 100%;
    text-align: center;
  }
}
.txt-name {
  direction: ltr !important;
}
.date-error {
  color: #dc3545 !important;
}