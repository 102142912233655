@import "./../../variable.scss";
.map-container {
  width: 100%;
  color: #000000;
  max-height: 350px;
  width: 100%;
  img {
    border: 1px solid $map-img-border-color;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
  }
}
.google-place {
  max-width: calc(100% - 40px);
  margin-left: 40px;
  height: 100px;
    display: table;
    position: relative;
    width: 100%;
  input {
    background: $type-msg-bg;
    margin-top: 10px;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 44px;
    padding: 0 10px;
    border-radius: 7px;
  }
  button {
    width: 90%;
  }
}
.btn-text-white {
  background: $darkgray-btn;
  color: $darkgray-btn-text-white;
}