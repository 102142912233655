@import "./../../variable.scss";

.rc-slider {
  position: absolute !important;
  margin: -51px 0;
  width: 87%;
}
.rc-slider-dot {
  left: 55.5556%;
  position: absolute;
  top: 0px;
  width: 3px;
  height: 22px;
  border-radius: 0px;
  border: none;
  background-color: #ffffff;
  transform: translateX(3px);
  transition: all 0.2s linear 0s;
}
.rc-slider-dot {
  bottom: -3px !important;
  margin-left: -5px !important;
}
.rc-slider-dot-active {
  left: 0%;
  position: absolute;
  top: 0px;
  width: 3px;
  height: 22px;
  border-radius: 0px;
  border: none;
  background-color: $slider-web-background-color;
  transform: translateX(3px);
  transition: all 0.2s linear 0s;
}

.rc-slider-rail,
.rc-slider-track {
  height: 7px;
  background-color: $slider-effect-background-color;
  border-radius: 0px;
}
.rc-slider-track {
  visibility: visible;
  height: 7px;
  background-color: $slider-web-background-color;
  border-radius: 0px;
  left: 0%;
  width: 22.2222%;
}
.submit-box {
  width: 7%;
  height: 118px;
  position: absolute;
  top: 0px;
  right: -15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $slider-web-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    color: #ffffff;
    font-weight: bolder;
    font-size: xx-large;
  }
}

.range-slider {
    width: 90%;
    padding: 89px 30px 5px 20px;
    margin: 0px 5%;
    background-color: #e5e5e5;
    background-image: linear-gradient(#e5e5e5, #e5e5e5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.moderate {
  color: $slider-web-background-color;
}
.rc-slider-handle {
  border: solid 2px $slider-web-background-color;
  background-color: $slider-web-background-color;
}

.rc-slider-handle:focus {
  border-color: $slider-web-background-color;
}
.rc-slider-handle-click-focused:focus {
  border-color: $slider-web-background-color;
  background-color: $slider-web-background-color !important;
  outline: none;
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rc-slider-handle rc-slider-handle-1 {
  display: none;
}

.rc-slider-handle:hover {
  border-color: $slider-web-background-color;
}

.rc-slider-handle-click-focused.rc-slider:focus {
  border: solid 2px $slider-web-background-color;
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rc-slider-tooltip-inner {
  background-color: $slider-web-background-color;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: $slider-web-background-color;
}
