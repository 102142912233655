@import "./../../variable.scss";
.specialization-main {
  background-color: $form-bg-color;
  border: 1px solid $chat-body-msg-shadow;
  .search-fill {
    position: relative;
    width: 90%;
    margin: 5%;
    padding-top: 5%;
    .form-control {
      background-color: $search-fill-color;
      border: none;
      height: 40px;
      padding-right: 40px;
    }
    a.serch-ic {
      position: absolute;
      right: 10px;
      top: 24px;
      img {
        width: 20px;
      }
    }
  }

  .doctors-list {
    width: 86%;
    height: 320px;
    overflow: auto;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: left;
    margin-left: 7%;
    margin-right: 7%;
    ul {
      display: inline;
      text-align: left;
      li {
        text-align: left;
        border-bottom: 1px solid $specialization-list-border-color;
        padding-top: 10px;
        padding-bottom: 10px;
        float: left;
        width: 100%;
        .doctor-list-detail {
          color: $specialization-list-text;
          a {
            font-size: 14px;
            text-transform: uppercase;
            margin: 0px;
            color: $doctor-name-text;
            font-family: $font-500;
          }
          p {
            font-size: 12px;
            margin: 0px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

/* list section */
.list-section-main {
  background-color: $form-bg-color;
}

.list-section-list {
  width: 66%;
  box-shadow: 0px 0px 10px $chat-body-msg-shadow;
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 48px;
  margin-top: 10px;
  border: 1px solid $chat-body-msg-shadow;
  ul {
    display: inline;
    text-align: left;
    li {
      text-align: left;
      border-bottom: 1px solid $specialization-list-border-color;
      padding-top: 10px;
      padding-bottom: 10px;
      float: left;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
      .doctor-list-detail {
        .list-section-btn {
          border: 1px solid $list-section-btn;
          color: #fff;
          color: $list-section-btn;
          border-radius: 8px;
          font-size: 13px;
          padding-left: 15px;
          padding-right: 15px;
          background-color: transparent;
          margin-top: 7px;
          &:hover {
            background-color: $list-section-btn;
            color: #fff;
          }
				}
				.list-section-btn.active {
					background-color: $list-section-btn;
          color: #fff;
        }
        .list-section-btn.disabled, {
          &:hover {
            border: 1px solid $color-card-link-disabled;
            color: $color-card-link-disabled;
            background-color: transparent;  
          }
          border: 1px solid $color-card-link-disabled;
          color: $color-card-link-disabled;
        }
      }
      span {
        color: $list-section-title;
        font-size: 14px;
        font-family: $font-700;
      }
      p {
        color: $doctor-subtitle-text;
        font-size: 13px;
        margin-bottom: 0px;
        font-family: $font-500;
      }
      img{
        width: 50px;
      }
    }
  }
}
