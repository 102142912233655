@import "./../../variable.scss";

.start-converstion {
  display: block;

  @media screen and (max-height: $break-laptop) {
    height: 86vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: $break-mobile) { 
  .avatar-container {
    height: 20vh;
  }
  .text-container {
    position: absolute;
    bottom: 0px;
  }  
}
.cover-pattern {
  width: 100%;
  // height: 235px;
  @media screen and (max-height: $break-laptop) {
    // height: 175px;
  }
  // background: $cover-pattern-bg;
  /* background-image: url(./../../public/images/bg_pattern.png); */
  // margin-bottom: 90px;
  color: $cover-pattern-text-color;
  h3 {
    font-size: 32px;
    text-align: center;
    font-family: $font-700;
    color: $cover-pattern-bg;
  }
  p {
    // font-size: 22px;
    font-size: 20px;
    text-align: center;
    height: 65px;
    color: $cover-pattern-bg;
    @media screen and (max-height: $break-laptop) {
      // padding-top: 10px;
      font-size: 14px;
    }
    padding: 0 20px;
    padding-top: 30px;
    @media screen and (max-width: $break-mobile) {
      padding: 0px;
      padding-top: 10px;
    }
  }
  .user-profile {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    top: 26%;
    @media screen and (max-height: $break-laptop) {
      // top: 0%;
    }
    .user-profile-male {
      left: 50%;
      border-radius: 100%;
      width: 90px;
      height: 90px;
      transform: translate(-120%, -20%);
      background-color: $user-profile-bg;
      position: absolute;
      @media screen and (max-width: $break-mobile) {
        // left: 20%;
        // transform: none;
        // top: -45px;
        width: 70px;
        height: 70px;
      }
      img {
        width: 100%;
      }
      span {
        cursor: pointer;
      }
      h5 {
        color: $color-avatar-title;
        font-family: $font-500;
        margin-top: 5px;
      }
    }

    .user-profile-female {
      left: unset;
      border-radius: 100%;
      right: 0px;
      width: 90px;
      height: 90px;
      transform: translate(-90%, -20%);
      background-color: $user-profile-bg;
      position: absolute;
      @media screen and (max-width: $break-mobile) {
        // right: 20%;
        transform: translate(-130%, -20%);
        // top: -45px;
        width: 70px;
        height: 70px;
      }
      img {
        width: 100%;
      }
      span {
        cursor: pointer;
      }
      h5 {
        color: $color-avatar-title;
        font-family: $font-500;
      }
    }
    h5 {
      font-family: $font-300;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: $body-text-normal;
      margin-top: 5px;
      margin-bottom: 3px;
    }
    h6 {
      font-weight: normal;
      font-size: 12px;
      text-align: center;
      color: $body-text-normal;
    }
    .status-online {
      background-color: $status-online-bg;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      bottom: 0px;
      right: 15px;
    }
  }
  .box-header-ic {
    top: 12px;
    &:hover {
      cursor: pointer;
    }
  }
}

.avatar-offline {
  background-color: $user-profile-offline-bg;
  .status-online {
    /* background-color: $status-offline-bg !important; */
    width: 15px;
    height: 15px;
    border-radius: 100%;
    position: absolute;
    bottom: 0px;
    right: 15px;
  }
  img {
    width: 100%;
    opacity: 0.2;
  }
  span {
    cursor: pointer;
  }
  h5 {
    color: $color-avatar-title;
    font-family: $font-500;
    opacity: 0.2;
  }
}

.white-pattern {
  // background-image: url(./../../public/images/bg_white_pattern.png);
  width: 100%;
  float: left;
  .body-text {
    width: 100%;
    float: left;
    @media screen and (max-height: $break-laptop) {
      margin-top: 30px !important;
      padding: 10px !important;
      margin: 10px 0px;
      padding-bottom: 0px !important;
      padding-top: 5px !important;
    }
    p {
      // font-weight: bold;
      font-size: 09px;
      line-height: 21px;
      text-align: center;
      color: $body-text-normal;
      margin-bottom: 0px !important;
      @media screen and (max-height: $break-laptop) {
        // padding: 1rem !important;
        // margin: 10px;
        padding-bottom: 0px !important;
        // padding-top: 5px !important;
        font-size: 10px !important;
        font-weight: bold;
      }
    }
  }
  .term-text {
    width: 100%;
    float: left;
    margin-top: 8px;
    p {
      font-weight: normal;
      font-size: 11px;
      text-align: center;
      margin-bottom: 0px;
      color: $term-text-para;
    }
    .p-text {
      font-weight: normal;
      font-size: 8px !important;
      text-align: center;
      margin-bottom: 0px;
      color: $term-text-para;
    }
    a {
      color: $term-text-link;
    }
  }
  .btn-text-white {
    background: $darkgray-btn;
    color: $darkgray-btn-text-white;
    width: 48%;
    margin: 0 1%;
    height: 51px;
    @media screen and (max-height: $break-laptop) {
      height: 44px;
    }
  }

  .btn-start {
    border-radius: 7px;
    background: $btn-start-bg;
    color: $btn-start-color;
    font-family: $font-500;
    height: 51px;
    @media screen and (max-height: $break-laptop) {
      height: 44px;
    }
    width: 48%;
    margin: 0 1%;
  }
}
.text_ic {
  width: 32px;
  margin-top: -22px;
}

/* theme b */

.cover-pattern-b {
  width: 100%;
  /* height: 294px; */
  background: $cover-pattern-bg;
  background-image: url(./../../public/images/star.svg);
  margin-bottom: 121px;
  background-position: left -140px top -140px;
  background-repeat: no-repeat;
  @media screen and (max-width: $break-mobile) {
    margin-bottom: 75px;
  }
  h3 {
    font-size: 22px;
    text-align: center;
    font-family: $font-300;
    color: $inti-main-text-white;
  }
  .user-profile-b {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    top: 10%;
    @media screen and (max-width: $break-mobile) {
      top: 0%;
      margin-bottom: 10px;
    }
  }
  .user-profile-male-b {
    left: calc(50% - 106px);
    border-radius: 100%;
    width: 106px;
    height: 106px;
    transform: translate(50%, -50%);
    @media screen and (max-width: $break-mobile) {
      left: calc(50% - 60px) !important;
      width: 60px;
      height: 60px;
    }
    background-color: $user-profile-bg;
    position: absolute;
    img {
      width: 100%;
    }
  }
  h5 {
    color: $color-avatar-title;
    font-family: $font-700;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  h6 {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: $term-text-para;
  }
}

.status-online-b {
  background-color: $status-online-bg;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  bottom: 0px;
  right: 15px;
}

.white-pattern-b {
  width: 100%;
  float: left;
  .body-text-b {
    width: 100%;
    float: left;
    @media screen and (max-height: $break-laptop) {
      padding-bottom: 0px !important;
      padding-top: 5px !important;
    }
    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $body-text-normal;
      @media screen and (max-height: $break-laptop) {
        padding: 2rem !important;
        margin: 10px;
        padding-bottom: 0px !important;
        padding-top: 5px !important;
        font-size: 14px;
      }
    }

  }
  .term-text-b {
    width: 100%;
    float: left;
    p {
      font-weight: normal;
      font-size: 10px;
      text-align: center;
      margin-bottom: 0px;
      color: $term-text-para;
    }
    a {
      color: $term-text-link;
    }
  }
  .btn-start {
    border-radius: 7px;
    background: $btn-start-bg;
    color: $btn-start-color;
    font-family: $font-500;
    height: 51px;
    width: 48%;
    margin: 1%;
  }
}

.font-arabic {
  font-family: $font-arabic-700 !important;
}
.font-arabic-light {
  font-family: $font-arabic-300 !important;
}
.font-arabic-16 {
  font-size: 12px;
  @media screen and (max-height: $break-laptop) {
    font-size: 12px;
  }
}
.font-arabic-18 {
  font-size: 18px;
  @media screen and (max-height: $break-laptop) {
    // font-size: 14px;    
  }
}
.font-arabic-20 {
  font-size: 20px;
  width: 100%;
  float: left;
  text-align: center;
  padding-bottom: 0px;
  @media screen and (max-height: $break-laptop) {
    font-size: 18px;    
  }
}


.screen-buttons-main{
  span{
    cursor: pointer;
    margin-top: 10px;
    float: left;
  }
  img{
    width: 100%;
  }
}
.style{
  z-index: 9999999999;
}