@import "variable.scss";

* {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
body {
  padding: 0px;
  margin: 0px;
  font-family: $font-300;
}
a:hover,
button:hover {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
button,
input,
.form-control:focus,
button:focus {
  outline: none;
  border: none;
}
.app {
  text-align: center;
}
h1 {
  color: $color-white;
}
.app-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-white;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.menu-pops {
  position: absolute;
  background: #fff;
  bottom: 10px;
  @media screen and (max-width: $break-mobile) {
    position: absolute;
    background: #fff;
    bottom: 80px;
    width: 40%;
    margin-left: 16px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 2;
  }
  // width: 60%;
  width: 40%;
  margin-left: 9px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 2;
  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #21252d;
    margin-bottom: 0;
    border-bottom: 1px solid $calender-header-border-color;
  }
  ul {
    display: inline;
    text-align: left;
    li {
      span {
        border-bottom: none;
        color: #0084fc;
        width: 100%;
        float: left;
        cursor: pointer;
        text-align: center;
        font-family: $font-500;
      }
    }
  }
}

.arrow_box {
  /* position: relative;
	background: #88b7d5; */
  border: 1px solid $humburger-menu-pop-arrow;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: $humburger-menu-pop-arrow;
  border-width: 10px;
  margin-left: -10px;
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: $scroll-background;
  border-radius: 10px;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: $scroll-background;
  border-radius: 10px;
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scroll-background;
}

.map-lat-lng {
  color: black;
}

.form-control {
  border: 1px solid #F2F2F2;
}
.bot-desc {
  text-align: center !important;
}
.card-body span {
  font-size: 14px;
  padding: 0 10px;
  margin-bottom: 0px;
}
/* lang-rtl */

.lang-rtl {
  direction: rtl;
  .main-chat-box {
    background-color: #fff;
  }
  .datefield-sector {
    .year {
      width: 78px !important;
    }
    .arrow_ic_blue {
      border-top-left-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
      border-top-right-radius:  0.25rem !important;
      border-bottom-right-radius:  0.25rem !important;
    }
    .tic_ic_green {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius:  0rem !important;
      border-bottom-right-radius:  0rem !important;
    }
  }
  .datesection {
    direction: ltr;
  }
  .general-img {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
  }
  .chat-logs {
    padding-left: 10px;
  }
  .fill_ic {
    padding: 8 0;
  }
  .tick_ic_green {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
  }
  .email-field {
    direction: ltr;
  }
  .arrow_ic_blue {
    -webkit-transform: rotate(180deg); 
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);  
  }
  .form-main {
    width: calc(100% - 55px);
    float: left;
    margin: 0 2.5%;
  }
  label {
    text-align: right;
  }
  .bot {
    float: right;
    text-align: right;
  }
  .term-text-b p {
    text-align: center !important;
  }
  .body-text-b p {
    text-align: center !important;
  }
  p {
    text-align: right !important;
  }
  .bot-msg {
    margin-right: 40px !important;
    margin-left: 0px !important;
    p {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 10px;
    }
    .chat-time {
      right: 0px;
    }
  }
  .bot-avatar {
    right: 0px;
  }

  .powered-by p {
    text-align: left !important; 
    direction: ltr;
  }

  .menu_ic {
    float: right;
  }
  .menu-pops {
    /* bottom: 80px; */
    // width: 60%;
    margin-right: 9px;
    z-index: 2;
    span {
      font-family: $font-arabic-300;
      font-size: 16px;
    }
    h4 {
      font-family: $font-arabic-700;
    }
  }
  .type-msg {
    margin-right: 8px;
    float: right;
  }
  .menu-pops ul {
    text-align: right;
  }

  .arrow_box:after,
  .arrow_box:before {
    right: 6px;
  }
  .arrow_box:after {
    margin-right: 2px;
  }
  .menu_ic img {
    margin-right: 7px;
    margin-left: unset;
  }
  .user-msg {
    margin: 0px 0px 0px 5px;
    float: left;
    clear: left;
    p {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;
    }
    .chat-time {
      left: 5px;
      right: unset;
      text-align: left;
    }
  }
  .input-msg-write input {
    float: right;
  }
  .float-left {
    float: right !important;
  }
  .chat-time {
    direction: ltr;
  }
  .type-msg {
    margin-right: 8px;
    width: 84%;
  }
  .card {
    .card-body {
      text-align: right;
    }
  }
  .headeravtar {
    right: 15px;
    left: unset;
  }
  .bot-name {
    text-align: right;
    right: 44px;
    left: unset;
    font-family: $font-arabic-700;
  }
  .doctor-list-detail {
    text-align: right;
  }
  .box-header-ic{
    right:unset;
    left: 16px;
  }
  // .btn-border-bbl{
  //   font-family: $font-arabic-700;
  // }
  .list-section-list {
    margin-left: 0px;
    margin-right: 48px;
  }
  .input-msg-write {
    input {
      font-family: $font-arabic-700;
      font-size: 12px;
    }
  }
  .google-place {
    max-width: 90%;
    margin-right: 25px;
    margin-left: 0px;
    direction: ltr;
  }
  .google-place button {
    width: 90%;
    float: left;
  }
  .search-ic {
    left: 10px;
    right: unset !important;
  }
  .search-fill {
    .form-control {
      padding-right: 10px;
      padding-left: 40px;
    }
  }
  .specialization-list ul {
    li {
      text-align: right;
    }
  }
  .DayPickerInput input {
    padding: 0 24px;
    background-position: right;
  }
  .datedate {
    padding: 0 24px;
    background-position: right;
    width: 213px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .phone-control {
    margin-right: 0px !important;
  }
  .bot-typer-name {
    padding-left: 5px;
  }

  .typing-indicator {
    margin-right: 10px !important;
    font-family: $font-arabic-300;
    float: right;
    direction: rtl;
    &::after {
      right: -5px;
      left: unset;
    }
    span {
      float: right;
    }
  }
  .otp-container {
    direction: ltr;
  }
}
.font-30 {
  font-size: 30px !important;
}


